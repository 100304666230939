'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/account/leadScoreModel/page',
  add(code, data) {
    return http.post('/account/leadPool', data, { code });
  },
  update(code, data) {
    return http.put('/account/leadPool', data, { code });
  },
  getCityList(code, params) {
    return http.get(
      '/authority/dictionaryItem/hierarchyBycode?code=CHINA_AREA',
      {
        params,
        code,
      }
    );
  },
  scroedetail(code, params) {
    return http.get('/account/leadScoreModel/selectScoreDetail', {
      params,
      code,
    });
  },
  delete(code, params) {
    return http.delete('/account/leadScoreModel', { params, code });
  },
  detail(code, id) {
    return http.get(`/account/leadScoreModel/${id}`, { code });
  },
  savedetail(code, data) {
    return http.put('/account/leadScoreModel', data, { code });
  },
  adddetail(code, data) {
    return http.post('/account/leadScoreModel', data, { code });
  },
};
