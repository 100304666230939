<template>
  <div class="center-box">
    <a-form ref="formRef" layout="vertical" :model="form" :rules="tagTypeRules">
      <div class="iss-main">
        <a-card title="基础信息" class="iss-card">
          <a-row :gutter="20">
            <a-col :span="12">
              <a-form-item name="tagName" label="标签名称">
                <a-input
                  v-model:value="form.tagName"
                  placeholder="请输入标签名称"
                  :disabled="isWatch"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12" v-if="form.tagTypeCode !== 'custom'">
              <a-form-item label="标签类别" name="tagTypeCode">
                <a-select
                  v-model:value="form.tagTypeCode"
                  placeholder="请选择"
                  :disabled="isWatch"
                  @change="handleChangetagTypeCode($event)"
                >
                  <a-select-option
                    v-for="item in tagTypeList"
                    :key="item.id"
                    :value="item.tagTypeCode"
                  >
                    {{ item.typeName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </a-card>
      </div>
      <div class="iss-main mb-0">
        <a-card
          title="标签规则"
          class="iss-card"
          v-if="tagType.tagTypeCode !== 'custom'"
          v-show="form.changetagTypeCode !== 'custom'"
        >
          <a-tab-pane
            v-for="pane in tagDetailVoList"
            :key="pane.tagCode"
            :tab="pane.tagName"
          />

          <div class="iss-regulations">
            <!--分群规则 start-->
            <ul class="condition-group-list">
              <!--条件组列表!!! -->
              <li
                class="condition-group-item"
                :key="index"
                v-for="(item, index) in form.activeConditionList"
              >
                <div class="condition-group-type-wrapper">
                  <a class="and-btn">且</a>
                </div>
                <ul class="condition-list">
                  <li
                    class="condition-item"
                    :key="ruleIndex"
                    v-for="(rule, ruleIndex) in item.tagRuleList"
                  >
                    <div class="condition-type-wrapper">
                      <span class="or-btn">或</span>
                    </div>
                    <div class="condition-wrapper">
                      <table class="condition-table">
                        <tr v-if="rule.type === 'property'">
                          <td style="width: 14%">
                            <span class="condition-type"> 属性满足 </span>
                          </td>
                          <td style="width: 18%">
                            <!--        tagDetailVoList[0].tagRuleList    -->
                            <a-form-item
                              :name="[
                                'activeConditionList',
                                index,
                                'tagRuleList',
                                ruleIndex,
                                'tagColumn',
                              ]"
                              :rules="required"
                            >
                              <a-select
                                v-model:value="rule.tagColumn"
                                :disabled="isWatch"
                                placeholder="请选择属性"
                                @change="handleChangeProperty($event, rule)"
                              >
                                <a-select-option
                                  v-for="p in propertyList"
                                  :key="p.colCode"
                                  :value="p.colCode"
                                >
                                  {{ p.colName }}
                                </a-select-option>
                              </a-select>
                            </a-form-item>
                          </td>
                          <!--                      属性操作符  -->
                          <td style="width: 15%">
                            <a-form-item
                              :name="[
                                'activeConditionList',
                                index,
                                'tagRuleList',
                                ruleIndex,
                                'operator',
                              ]"
                              :rules="required"
                            >
                              <a-select
                                v-model:value="rule.operator"
                                :disabled="isWatch"
                                placeholder="请选择操作符"
                              >
                                <a-select-option
                                  v-for="ol in actionList"
                                  :key="ol.value"
                                  :value="ol.value"
                                >
                                  {{ ol.label }}
                                </a-select-option>

                                <!--                            <a-select-option-->
                                <!--                              v-for="o in operatorListMap[rule.dataType]"-->
                                <!--                              :key="o.symbol"-->
                                <!--                              :value="o.symbol"-->
                                <!--                            >-->
                                <!--                              {{ o.symbolName }}-->
                                <!--                            </a-select-option>-->
                              </a-select>
                            </a-form-item>
                          </td>
                          <td v-if="rule.name === 'country'" style="width: 53%">
                            <el-autocomplete
                              style="width: 50%"
                              v-if="rule.operator !== '('"
                              v-model="rule.value"
                              :fetch-suggestions="
                                (queryString, cb) =>
                                  cb(
                                    areaOptions.filter(item =>
                                      item.value.includes(queryString)
                                    )
                                  )
                              "
                              placeholder="请输入..."
                            />
                          </td>
                          <td
                            v-else-if="rule.name === 'region'"
                            style="width: 53%"
                          >
                            <el-autocomplete
                              style="width: 50%"
                              v-if="rule.operator !== '('"
                              v-model="rule.value"
                              :fetch-suggestions="
                                (queryString, cb) =>
                                  cb(
                                    areaDeptOptions.filter(item =>
                                      item.value.includes(queryString)
                                    )
                                  )
                              "
                              placeholder="请输入地区..."
                            />
                          </td>
                          <td
                            v-else-if="rule.name === 'industry_lv1'"
                            style="width: 53%"
                          >
                            <el-autocomplete
                              style="width: 50%"
                              v-if="rule.operator !== '('"
                              v-model="rule.value"
                              :fetch-suggestions="
                                (queryString, cb) =>
                                  cb(
                                    industryLv1Options.filter(item =>
                                      item.value.includes(queryString)
                                    )
                                  )
                              "
                              placeholder="请输入行业..."
                            />
                          </td>
                          <td
                            v-else-if="rule.name === 'customer_type'"
                            style="width: 53%"
                          >
                            <el-autocomplete
                              style="width: 50%"
                              v-if="rule.operator !== '('"
                              v-model="rule.value"
                              :fetch-suggestions="
                                (queryString, cb) =>
                                  cb(
                                    CUSTOMETYPEOptions.filter(item =>
                                      item.value.includes(queryString)
                                    )
                                  )
                              "
                              placeholder="请输入客户类型..."
                            />
                          </td>
                          <td
                            v-else-if="rule.name === 'job_title'"
                            style="width: 53%"
                          >
                            <el-autocomplete
                              style="width: 50%"
                              v-if="rule.operator !== '('"
                              v-model="rule.value"
                              :fetch-suggestions="
                                (queryString, cb) =>
                                  cb(
                                    JobTitleOptions.filter(item =>
                                      item.value.includes(queryString)
                                    )
                                  )
                              "
                              placeholder="请输入客户类型..."
                            />
                          </td>
                          <td
                            v-else-if="rule.name === 'is_activate'"
                            style="width: 53%"
                          >
                            <a-form-item
                              :name="[
                                'activeConditionList',
                                index,
                                'tagRuleList',
                                ruleIndex,
                                'value',
                              ]"
                              :rules="required"
                            >
                              <a-select
                                v-if="rule.operator !== '('"
                                v-model="rule.value"
                                style="width: 50%"
                                placeholder="请选择"
                              >
                                <a-select-option
                                  v-for="item in is_activateOptions"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value"
                                />
                              </a-select>
                            </a-form-item>
                          </td>
                          <td
                            v-else-if="rule.dataType === 'total_time'"
                            style="width: 53%"
                          >
                            <a-form-item
                              :name="[
                                'activeConditionList',
                                index,
                                'tagRuleList',
                                ruleIndex,
                                'value',
                              ]"
                              :rules="required"
                            >
                              <el-date-picker
                                v-if="rule.operator !== '('"
                                v-model="rule.value"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              />
                            </a-form-item>
                            <a-form-item
                              :name="[
                                'activeConditionList',
                                index,
                                'tagRuleList',
                                ruleIndex,
                                'secondValue',
                              ]"
                              :rules="required"
                            >
                              <el-date-picker
                                v-if="
                                  ['()', '[)', '(]', '[]'].includes(
                                    rule.operator
                                  )
                                "
                                v-model="rule.secondValue"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                              />
                            </a-form-item>
                          </td>
                          <td
                            v-else-if="
                              rule.tagColumn === 'province' ||
                              rule.tagColumn === 'city'
                            "
                            style="width: 53%"
                          >
                            <a-form-item
                              :name="[
                                'activeConditionList',
                                index,
                                'tagRuleList',
                                ruleIndex,
                                'value',
                              ]"
                              :rules="required"
                              style="width: 100%"
                            >
                              <a-select
                                v-model:value="rule.value"
                                show-search
                                :disabled="isWatch"
                                placeholder="请选择"
                              >
                                <a-select-option
                                  v-for="item in rule.tagColumn === 'province'
                                    ? list.province
                                    : list.city"
                                  :key="item.id"
                                  :value="item.itemCode"
                                  :item="item"
                                >
                                  {{ item.itemNameCn }}
                                </a-select-option>
                              </a-select>
                            </a-form-item>
                          </td>
                          <!--  属性满足输入框 -->
                          <td v-else style="width: 53%">
                            <div style="display: flex">
                              <a-form-item
                                :name="[
                                  'activeConditionList',
                                  index,
                                  'tagRuleList',
                                  ruleIndex,
                                  'value',
                                ]"
                                :rules="required"
                                style="width: 100%"
                              >
                                <a-select
                                  v-if="['gender'].includes(rule.tagColumn)"
                                  v-model:value="rule.value"
                                  :disabled="isWatch"
                                  :options="genderList"
                                  placeholder="请选择"
                                />
                                <a-select
                                  v-else-if="
                                    ['receive_consult'].includes(rule.tagColumn)
                                  "
                                  v-model:value="rule.value"
                                  :disabled="isWatch"
                                  :options="receiveConsultList"
                                  placeholder="请选择"
                                />
                                <!--                              <a-select-->
                                <!--                                  v-model:value="rule.value"-->
                                <!--                                  :disabled="isWatch"-->
                                <!--                                  placeholder="请选择行业"-->
                                <!--                                  v-else-if="['industry_lv1_code'].includes(rule.tagColumn)"-->
                                <!--                              >-->
                                <!--                                <a-select-option-->
                                <!--                                    v-for="item in industryList"-->
                                <!--                                    :key="item.id"-->
                                <!--                                    :value="item.itemCode"-->
                                <!--                                    :item="item"-->
                                <!--                                >-->
                                <!--                                  {{item.itemNameCn}}-->
                                <!--                                </a-select-option>-->
                                <!--                              </a-select>-->
                                <!--                          包含有 公司和职位 -->
                                <a-textarea
                                  v-else-if="
                                    ['position', 'company'].includes(
                                      rule.tagColumn
                                    )
                                  "
                                  v-model:value="rule.value"
                                  auto-size
                                  :disabled="isWatch"
                                  placeholder="请输入..."
                                />
                                <a-textarea
                                  v-else
                                  v-model:value="rule.value"
                                  auto-size
                                  :disabled="isWatch"
                                  placeholder="请输入..."
                                />
                              </a-form-item>
                              <a-form-item style="width: 2%">
                                <span
                                  v-if="
                                    ['()', '[)', '(]', '[]'].includes(
                                      rule.operator
                                    )
                                  "
                                >
                                  -
                                </span>
                              </a-form-item>
                              <a-form-item
                                style="width: 100%"
                                :name="[
                                  'activeConditionList',
                                  index,
                                  'tagRuleList',
                                  ruleIndex,
                                  'secondValue',
                                ]"
                                :rules="required"
                                v-if="
                                  ['()', '[)', '(]', '[]'].includes(
                                    rule.operator
                                  )
                                "
                              >
                                <a-input
                                  :disabled="isWatch"
                                  v-model:value="rule.secondValue"
                                  placeholder="请输入..."
                                />
                              </a-form-item>
                              <span
                                style="
                                  margin-left: 10px;
                                  margin-bottom: 5px;
                                  display: inline-block;
                                "
                                v-show="rule.tagColumn === 'email'"
                              >
                                <a-tooltip placement="top">
                                  <template #title>
                                    1.@issmart.com.cn可进行邮箱后缀匹配；<br />
                                    2.issmart@可进行前缀匹配；
                                  </template>
                                  <QuestionCircleOutlined />
                                </a-tooltip>
                              </span>
                            </div>
                          </td>
                          <td>
                            <a-form-item>
                              <a
                                class="remove-btn"
                                v-if="!isWatch"
                                @click="removeRule(index, ruleIndex)"
                              />
                            </a-form-item>
                          </td>
                        </tr>
                        <!--行为满足-->
                        <tr v-else-if="rule.type === 'behavior'">
                          <td style="width: 14%">
                            <span class="condition-type"> 行为满足 </span>
                          </td>
                          <td style="width: 18%">
                            <a-form-item
                              :name="[
                                'activeConditionList',
                                index,
                                'tagRuleList',
                                ruleIndex,
                                'tagColumn',
                              ]"
                              :rules="required"
                            >
                              <a-select
                                v-model:value="rule.tagColumn"
                                :disabled="isWatch"
                                placeholder="请选择行为"
                                @change="handleChangeAction($event, rule)"
                              >
                                <a-select-option
                                  v-for="ap in actionPropertyList"
                                  :key="ap.colCode"
                                  :value="ap.colCode"
                                >
                                  {{ ap.colName }}
                                </a-select-option>
                              </a-select>
                            </a-form-item>
                          </td>
                          <td style="width: 15%">
                            <a-form-item
                              :name="[
                                'activeConditionList',
                                index,
                                'tagRuleList',
                                ruleIndex,
                                'action',
                              ]"
                              :rules="required"
                            >
                              <a-select
                                v-model:value="rule.action"
                                :disabled="isWatch"
                                placeholder="请选择功能"
                              >
                                <a-select-option
                                  v-for="o in rule.behaviorActionList"
                                  :key="o.value"
                                  :value="o.value"
                                >
                                  {{
                                    o.name +
                                    `${o.unit ? '（' + o.unit + '）' : ''}`
                                  }}
                                </a-select-option>
                              </a-select>
                            </a-form-item>
                          </td>
                          <td style="width: 18%">
                            <a-form-item
                              :name="[
                                'activeConditionList',
                                index,
                                'tagRuleList',
                                ruleIndex,
                                'operator',
                              ]"
                              :rules="required"
                            >
                              <a-select
                                v-model:value="rule.operator"
                                :disabled="isWatch"
                                placeholder="请选择操作符"
                              >
                                <a-select-option
                                  v-for="ol in operatorList"
                                  :key="ol.value"
                                  :value="ol.value"
                                >
                                  {{ ol.label }}
                                </a-select-option>
                              </a-select>
                            </a-form-item>
                          </td>
                          <!--                      @change="() => (rule.value = parseInt(rule.value))"-->
                          <td style="width: 30%">
                            <div style="display: flex">
                              <a-form-item
                                :name="[
                                  'activeConditionList',
                                  index,
                                  'tagRuleList',
                                  ruleIndex,
                                  'value',
                                ]"
                                :rules="required"
                                :style="{
                                  width:
                                    rule.operator === '[]' ? '49%' : '100%',
                                }"
                              >
                                <a-input-number
                                  v-model:value="rule.value"
                                  :disabled="isWatch"
                                  :min="1"
                                  :precision="0"
                                  placeholder="请输入正整数"
                                />
                              </a-form-item>
                              <a-form-item style="width: 2%">
                                <span v-show="rule.operator === '[]'"> - </span>
                              </a-form-item>

                              <a-form-item
                                :name="[
                                  'activeConditionList',
                                  index,
                                  'tagRuleList',
                                  ruleIndex,
                                  'secondValue',
                                ]"
                                :rules="required"
                                style="width: 49%"
                                v-if="rule.operator === '[]'"
                              >
                                <!--                                v-show="rule.operator === '[]'"    -->
                                <a-input-number
                                  :disabled="isWatch"
                                  :min="1"
                                  :precision="0"
                                  v-model:value="rule.secondValue"
                                  placeholder="请输入正整数"
                                />
                              </a-form-item>
                              <!--                          </div>-->
                              <!--                        </td>-->
                              <!--                        <td style="width: 5%">-->
                              <!--                            <a-form-item style="padding: 0px 5px 0 5px">-->
                              <!--                              <span v-show="rule.action === 'count'"> 次 </span>-->
                              <!--                            </a-form-item>-->
                              <!--                            <a-form-item>-->
                              <!--                              <span v-show="rule.action === 'total_time'">-->
                              <!--                                分钟-->
                              <!--                              </span>-->
                              <!--                            </a-form-item>-->
                              <a-form-item>
                                <span v-show="rule.operator === '[]'">
                                  <a-tooltip
                                    title="包含起始值与结束值"
                                    placement="top"
                                  >
                                    <QuestionCircleOutlined />
                                  </a-tooltip>
                                </span>
                              </a-form-item>
                              <a-form-item>
                                <a
                                  class="remove-btn"
                                  v-if="!isWatch"
                                  @click="removeRule(index, ruleIndex)"
                                />
                              </a-form-item>
                            </div>
                          </td>
                        </tr>
                        <!--                        行为满足2-->
                        <tr v-if="rule.type === 'behavior'">
                          <td></td>
                          <td colspan="3">
                            <!--      v-model:value="rule.secondValueArray"   -->
                            <a-form-item
                              :name="[
                                'activeConditionList',
                                index,
                                'tagRuleList',
                                ruleIndex,
                                'timeValue',
                              ]"
                              :rules="required"
                            >
                              <a-range-picker
                                v-model:value="rule.timeValue"
                                style="width: 100%"
                                show-time
                                value-format="yyyy-MM-DD HH:mm:ss"
                                :disabled="isWatch"
                                @change="
                                  changeBehaviorTime(index, ruleIndex, rule)
                                "
                              />
                            </a-form-item>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </li>

                  <!--条件添加按钮 -->
                  <li class="condition-item">
                    <div class="condition-type-wrapper">
                      <a class="or-btn" v-if="!isWatch"></a>
                      <div class="condition-type-list">
                        <a
                          class="condition-type-a"
                          @click="addCondition('property', index)"
                        >
                          属性满足
                        </a>
                        <a
                          class="condition-type-a"
                          @click="addCondition('behavior', index)"
                        >
                          行为满足
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <!--条件组添加按钮 -->
              <li class="condition-group-item">
                <div class="condition-group-type-wrapper">
                  <a class="and-btn" v-if="!isWatch"></a>
                  <div class="condition-type-list" style="top: -33%">
                    <a
                      class="condition-type-a"
                      @click="addConditionGroup('property')"
                    >
                      属性满足
                    </a>
                    <a
                      class="condition-type-a"
                      @click="addConditionGroup('behavior')"
                    >
                      行为满足
                    </a>
                  </div>
                </div>
              </li>
            </ul>
            <!--分群规则 end-->
          </div>
        </a-card>
      </div>
    </a-form>
  </div>
  <div class="iss-main">
    <a-space>
      <a-button type="primary" v-if="!isWatch" @click="handleSave">
        保存
      </a-button>
      <a-button @click="$router.go(-1)"> 返回 </a-button>
    </a-space>
  </div>
</template>

<script>
import {
  Card,
  Form,
  Row,
  Col,
  // Radio,
  // Tabs,
  Tooltip,
  Select,
  Space,
  InputNumber,
  // Spin,
  DatePicker,
  message,
} from 'ant-design-vue';
import tagApi from '@/api/tag.js';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import integralApi from '@/api/integral.js';
import { reactive, ref, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import cluescoreApi from '@/api/cluescore';

export default {
  components: {
    ACard: Card,
    AForm: Form,
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    AInputNumber: InputNumber,
    ASelectOption: Select.Option,
    ASpace: Space,
    // ASpin: Spin,
    ARangePicker: DatePicker.RangePicker,
    ATooltip: Tooltip,
    QuestionCircleOutlined,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const formRef = ref();
    const required = { required: true, message: '不能为空' };
    const form = reactive({
      tagName: null,
      tagTypeCode: null,
      activeConditionList: [],
      tagRuleList: { tagRuleList: [] },
      changetagTypeCode: '',
    });

    const list = reactive({
      province: [],
      city: [],
    });
    const isWatch = route.params.status === 'watch';
    const state = reactive({
      code: '',
      spinning: false,
      tagType: {},
      tagTypeList: [],
      industryList: [],
      tagTypeRules: {
        tagTypeCode: [required],
        tagName: [required],
        type: [required],
        subType: [required],
      },

      userTypeList: [
        { value: 'allMember', label: '会员用户' },
        { value: 'allContact', label: '联系人' },
        { value: 'allLeads', label: '线索数据' },
      ],
      activityIdNameList: [],
      visible: false,
      confirmLoading: true,
      tagDetailVoList: [],
      activeKey: '',
      // activeConditionList: [],
      // tagRuleList: { tagRuleList: [] },
      timeValue: '',
      propertyList: [],
      // 属性满足操作符接口
      operatorListMap: {},
      actionPropertyList: [], //动作属性
      genderList: [
        { value: '0', label: '男' },
        { value: '1', label: '女' },
      ],
      receiveConsultList: [
        { value: '1', label: '接受' },
        { value: '0', label: '不接受' },
      ],
      behaviorAllList: [
        { label: '总次数', value: 'count' },
        { label: '总时长', value: 'total_time' },
      ],
      behaviorLiveList: [{ label: '总时长', value: 'total_time' }],
      behaviorCountList: [{ label: '总次数', value: 'count' }],
      operatorList: [
        { label: '等于', value: '=' },
        { label: '不等于', value: '!=' },
        { label: '大于', value: '>' },
        { label: '小于', value: '<' },
        { label: '介于', value: '[]' },
      ],
      // 属性满足操作符
      actionList: [{ label: '等于', value: '=' }],
      areaOptions: [],
      segment: {
        name: '',
        remark: '',
        label: 'label',
        memberGroupDetailList: [],
        conditionGroupList: [],
        groupRuleList: [],
      },
      areaDeptOptions: [],
      industryLv1Options: [],
      is_activateOptions: [
        { value: '0', label: '未激活' },
        { value: '1', label: '待激活' },
        { value: '2', label: '已激活' },
      ],
      CUSTOMETYPEOptions: [],
      JobTitleOptions: [],
      loading: false,
    });

    // 点击编辑拿到的数据
    const initTagDetails = () => {
      const id = route.params.id;
      if (id) {
        if (id === 'add') {
          state.code = 'tagmanage:tagAdd';
        } else {
          state.code = 'tagmanage:tagupdate';
          tagApi.getTagDetails(state.code, id).then(data => {
            Object.assign(form, data);
            form.changetagTypeCode = data.tagTypeCode;
            state.tagType = data;
            if (data.tagConditionList.length !== 0) {
              data.tagConditionList.forEach(item => {
                item.tagRuleList?.forEach(i => {
                  if (i.actionType) {
                    i.tagColumn = i.tagColumn + ',' + i.actionType;
                  }
                  const temp = state.actionPropertyList.find(
                    items => items.colCode === i.tagColumn
                  );
                  i.behaviorActionList = temp?.action || [];

                  i.type === 'behavior' &&
                    i.timeValue !== null &&
                    (i.timeValue = i.timeValue.split('@@'));
                });
              });
              form.activeConditionList = data.tagConditionList;
            }

            if (state.tagDetailVoList.length) {
              handleTabChange(state.tagDetailVoList[0].tagRuleList[0].tagCode);
            }
          });
        }
      }
    };

    // 获取分类类表接口
    const getTypeList = params => {
      tagApi.getTagTypeList('', params).then(data => {
        // 关注这个值是否赋值错误
        state.tagTypeList = data;
      });
    };
    getTypeList();

    // 查看调取的方法
    // const initActivityIdNameList = () => {
    //   tagApi.getTagDetails(state.code).then(data => {
    //     state.activityIdNameList = data;
    //   });
    // };

    const handleChangeAction = (value, rule) => {
      rule.rule = '';
      rule.value = '';
      rule.secondValue = '';
      const temp = state.actionPropertyList.find(i => i.colCode === value);
      rule.behaviorActionList = temp.action;
      rule.dataType = temp.dataType;
    };
    const handleSave = async () => {
      if (
        form.activeConditionList.length === 0 &&
        form.changetagTypeCode !== 'custom'
      ) {
        return message.error('请配置至少一条规则');
      }
      let activeConditionList = form.activeConditionList.map(item => {
        let items = {};
        items.tagRuleList = item.tagRuleList?.map(item => {
          let params = { ...item };
          if (item.tagColumn.indexOf(',')) {
            params.actionType = item.tagColumn.split(',')[1];
            params.tagColumn = item.tagColumn.split(',')[0];
          }

          item.type === 'behavior' &&
            item.timeValue !== undefined &&
            Array.isArray(item.timeValue) &&
            (params.timeValue = item.timeValue.join('@@'));
          return params;
        });
        return items;
      });

      await formRef.value.validate();
      state.spinning = true;
      let params = {
        tagName: form.tagName,
        tagTypeCode: form.tagTypeCode,
        // tagStrategy: state.tagStrategy,
        tagConditionList: activeConditionList,
        tagCode: state.tagType.tagCode,
        id: state.tagType.id,
      };

      let type = '';
      const id = route.params.id;
      if (state.tagType.tagTypeCode !== 'custom') {
        id === 'add' ? (type = 'tagAdd') : (type = 'tagUpdate');
      } else {
        //  调取专门保存自定义标签接口
        type = 'tagCustomiZation';
      }

      try {
        await tagApi[type](`form:${type}`, params);
        router.go(-1);
        message.success('操作成功');
        setTimeout(() => {
          state.confirmLoading = false;
        }, 1500);
      } catch (e) {
        console.log(e);
      }
      state.spinning = false;
    };

    const handleTabChange = activeKey => {
      const vo = state.tagDetailVoList.find(item => {
        return item.tagRuleList[0].tagCode === activeKey;
      });
      state.activeKey = activeKey;
      form.activeConditionList.push(vo);
    };

    const handleTabEdit = (targetKey, action) => {
      if (action === 'remove') {
        const index = state.tagDetailVoList.findIndex(
          item => item.tagCode === targetKey
        );
        state.tagDetailVoList.splice(index, 1);
        if (state.activeKey === targetKey) {
          const length = state.tagDetailVoList.length;
          if (length) {
            handleTabChange(state.tagDetailVoList[length - 1].tagCode);
          } else {
            state.activeKey = '';
          }
        }
      }
    };

    const addConditionGroup = type => {
      form.activeConditionList.push({ tagRuleList: [] });
      addCondition(type, form.activeConditionList.length - 1);
    };
    const addCondition = (type, index) => {
      form.activeConditionList[index].tagRuleList.push({
        col: '',
        actionType: '',
        conditionId: index + 1,
        action: null,
        operator: null,
        secondValueArray: [],
        value: '',
        dataType: '',
        type,
      });
    };

    const removeRule = (index, ruleIndex) => {
      form.activeConditionList[index].tagRuleList.splice(ruleIndex, 1);
      if (!form.activeConditionList[index].tagRuleList.length) {
        form.activeConditionList.splice(index, 1);
      }
    };
    // 选属性触发的方法
    const handleChangeProperty = (value, rule) => {
      rule.operator = '';
      rule.value = '';
      rule.secondValue = '';
      const temp = state.propertyList.find(i => i.colCode === value);
      rule.dataType = temp.dataType;
    };
    const handleChangetagTypeCode = value => {
      form.changetagTypeCode = value;
    };

    // 获取地区选择列表
    const getAreaOptions = async () => {
      const res = await integralApi.getAreaOptions();
      const areas = Object.keys(res.data.AREA);
      state.areaOptions = areas.map(key => ({
        en: key,
        value: res.data.AREA[key],
      }));
    };

    const areaChEntransformation = (area, targetLanguage) => {
      if (targetLanguage === 'Ch') {
        const areaObj = state.areaOptions.find(areaObj => areaObj.en === area);
        return areaObj ? areaObj.value : area;
      }

      if (targetLanguage === 'En') {
        const areaObj = state.areaOptions.find(
          areaObj => areaObj.value === area
        );
        return areaObj ? areaObj.en : area;
      }
    };

    // 行为满足的时间
    const changeBehaviorTime = (index, conditionIndex) => {
      let secondValueArray =
        form.activeConditionList[index].tagRuleList[conditionIndex].timeValue;
      form.activeConditionList[index].tagRuleList[conditionIndex].timeValue =
        secondValueArray;
    };

    //新增或修改
    const saveTagDetail = () => {
      const loading = this.$loading({
        lock: true,
        text: 'loading…',
        background: 'rgba(0, 0, 0, 0.4)',
      });
      // 格式化标签群组国家数据 英文 =》 中文
      state.segment.conditionGroupList.map(group => {
        group.tagRuleList.map(rule => {
          if (rule.name !== 'country') return;
          Object.assign(rule, {
            value: state.areaChEntransformation(rule.value, 'En'),
          });
        });
      });
      // 格式化标签分层国家数据 英文 =》 中文
      state.segment.tagDetailVoList.map(tagDetailVo => {
        tagDetailVo.conditionList.map(condition => {
          condition.tagRuleList.map(rule => {
            if (rule.name !== 'country') return;
            Object.assign(rule, {
              value: state.areaChEntransformation(rule.value, 'En'),
            });
          });
        });
      });

      tagApi
        .updateDetail(this.code, this.segment)
        .then(() => {
          // let data = response.data.data;
          message({
            message: '成功',
            type: 'success',
          });
          loading.close();
          router.back();
        })
        .catch(error => {
          loading.close();
          console.error(error);
          state.loading = false;
        });
    };
    // 行业接口
    tagApi.getItemTree('', { code: 'INDUSTRY' }).then(data => {
      state.industryList = data;
    });

    const getProvinceList = () => {
      return cluescoreApi.getCityList('', {
        hierarchy: 1,
      });
    };
    const getCityList = () => {
      return cluescoreApi.getCityList('', {
        hierarchy: 2,
      });
    };
    const getOperatorListMap = () => {
      return tagApi.getPropertyRule(state.code);
    };
    const getPropertyList = () => {
      return tagApi.getPropertySelector(state.code);
    };
    const getActionPropertyList = () => {
      return tagApi.getActionSelector(state.code);
    };

    Promise.all([
      getProvinceList(),
      getCityList(),
      getOperatorListMap(),
      getPropertyList(),
      getActionPropertyList(),
    ]).then(values => {
      list.province = values[0];
      list.city = values[1];
      // state.operatorListMap = values[2];
      // console.log('state.operatorListMap', state.operatorListMap)
      state.propertyList = values[3].contact;
      values[4].behavior.forEach(item => {
        if (item.actionType) {
          item.colCode = item.colCode + ',' + item.actionType;
          item.col = item.colCode + ',' + item.actionType;
        }
      });
      state.actionPropertyList = values[4].behavior;
      initTagDetails();
    });

    return {
      isWatch,
      form,
      required,
      list,
      formRef,
      ...toRefs(state),
      initTagDetails,
      handleSave,
      // handleOk,
      // handleCancel,
      handleChangeAction,
      handleTabChange,
      handleTabEdit,
      addConditionGroup,
      addCondition,
      removeRule,
      handleChangeProperty,
      handleChangetagTypeCode,
      getAreaOptions,
      areaChEntransformation,
      changeBehaviorTime,
      saveTagDetail,
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main.mb-0 {
  margin-bottom: 0px;
}
.center-box {
  max-height: calc(100vh - 160px);
  overflow-y: auto;
  border-radius: 8px;
}

.iss-radio {
  display: flex;
  flex-direction: column;
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    :deep(span:nth-child(2)) {
      padding-right: 0;
    }
  }
}

.iss-tabs :deep(.ant-tabs-bar) {
  margin-bottom: 0;
}

.iss-regulations {
  //padding: 10px 16px;
  //border: 1px solid #e8e8e8;
  //border-width: 0 1px 1px 1px;
  //border-radius: 0 0 4px 4px;
  min-height: 315px;
}

.iss-regulations .condition-group-item {
  display: flex;
  padding-bottom: 30px;
}
.iss-regulations .condition-group-type-wrapper {
  position: relative;
}
.iss-regulations .condition-group-type-wrapper:before {
  content: '';
  position: absolute;
  display: block;
  top: 36px;
  bottom: -30px;
  left: 50%;
  width: 3px;
  height: 107%;
  transform: translateX(-50%);
  background-color: #adc3ff;
}

.iss-regulations
  .condition-group-item:last-child
  .condition-group-type-wrapper:before {
  display: none;
}

.iss-regulations .and-btn {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  background-color: #85a1ff;
  border-radius: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.iss-regulations .and-btn:before {
  //content: 'And';
  position: relative;
  display: inline-block;
}

//.iss-regulations .condition-group-item:first-child .and-btn:before {
//  content: 'A';
//}

.iss-regulations .condition-group-item:last-child .and-btn:before {
  content: '+';
  font-size: 30px;
  top: -2px;
}

.iss-regulations .remove-btn {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: rgb(253, 0, 0);
  text-align: center;
  //background-color: rgb(253, 0, 0);
  border-radius: 100%;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.iss-regulations .remove-btn:before {
  content: '✕';
  position: relative;
  display: inline-block;
}

.iss-regulations .condition-group-item:first-child .remove-btn:before {
  content: '✕';
}

.iss-regulations .condition-group-item:last-child .remove-btn:before {
  content: '✕';
  font-size: 30px;
  top: -2px;
}

.iss-regulations .condition-type-list {
  display: none;
  vertical-align: middle;
}

.iss-regulations .condition-type-wrapper:hover .condition-type-list,
.iss-regulations .condition-group-type-wrapper:hover .condition-type-list {
  display: inline-block;
}

.iss-regulations .condition-type-a {
  //display: inline-block;
  display: block;
  width: auto;
  height: 24px;
  line-height: 24px;
  padding: 0px 12px;
  border-radius: 24px;
  margin: 5px;
  color: #5979f8;
  background-color: #d6e2ff;
}

.iss-regulations .condition-type-a:hover {
  color: #fff;
  background-color: #5979f8;
  text-decoration: none;
}

.iss-regulations .or-btn {
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  color: #5979f8;
  text-align: center;
  background-color: #d6e2ff;
  border-radius: 100%;
  vertical-align: middle;
  cursor: pointer;
}

.iss-regulations .condition-item:last-child .or-btn {
  color: #fff;
  background-color: #85a1ff;
  border: 1px solid #d6e2ff;
}

.iss-regulations .or-btn:before {
  //content: 'Or';
  position: relative;
  display: inline-block;
}

.iss-regulations .condition-item:first-child .or-btn:before {
  //content: 'O';
}

.iss-regulations .condition-item:last-child .or-btn:before {
  content: '+';
  font-size: 26px;
  top: -2px;
}

.iss-regulations .condition-list {
  padding-left: 30px;
  width: 100%;
}

.iss-regulations .condition-item {
  display: flex;
}

.condition-type-list {
  position: absolute;
  width: 100px;
  top: 0;
}

.iss-regulations .condition-wrapper {
  width: 100%;
  white-space: nowrap;
}

.iss-regulations .condition-type-wrapper {
  position: relative;
  padding-top: 15px;
}

.iss-regulations .condition-type-wrapper:before {
  content: '';
  position: absolute;
  display: block;
  top: 49px;
  bottom: -30px;
  left: 50%;
  width: 1px;
  transform: translateX(-50%);
  background-color: #ebeff1;
}

.iss-regulations .condition-item:last-child .condition-type-wrapper:before {
  display: none;
}

.iss-regulations .condition-table {
  width: 100%;
}

.iss-regulations .condition-table td {
  padding: 30px 5px 0 5px;
}

.iss-regulations .condition-type {
  display: inline-block;
  color: #5979f8;
  padding-left: 10px;
}

.iss-regulations .condition-close {
  cursor: pointer;
}

.condition-table .ant-select {
  width: 100%;
}
.ant-space-item .ant-tag-has-color .anticon-close {
  color: #000;
}
.iss-main-rule {
  height: calc(100vh - 251px);
  overflow-y: auto;
}
.ant-input-number {
  width: 100%;
}
</style>
